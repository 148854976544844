import React, { Fragment, useEffect, useState } from 'react';
import './App.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSun } from '@fortawesome/free-solid-svg-icons';
import { Button } from 'react-bootstrap';

function App() {
  /*
  if (window.location.protocol !== 'https:') {
    window.location.replace(`https:${window.location.href.substring(window.location.protocol.length)}`);
  }
  */
  const theme = localStorage.getItem("theme")
  const lFormat = localStorage.getItem("format")
  const lDay = localStorage.getItem("day")
  const lRelative = localStorage.getItem("relative")
  const [light, setLight] = useState<boolean>(theme ? JSON.parse(theme) : true)
  const [day, setDay] = useState<boolean>(lDay ? JSON.parse(lDay) : true)
  const [relative, setRelative] = useState<boolean>(lRelative ? JSON.parse(lRelative) : true)
  const [format, setFormat] = useState<string>(lFormat ? JSON.parse(lFormat) : "t")
  const [times, setTimes] = useState<Date[]>([]);

  useEffect(() => {
    localStorage.setItem("theme", JSON.stringify(light))
  }, [light])
  useEffect(() => {
    localStorage.setItem("day", JSON.stringify(day))
  }, [day])
  useEffect(() => {
    localStorage.setItem("relative", JSON.stringify(relative))
  }, [relative])
  useEffect(() => {
    localStorage.setItem("format", JSON.stringify(format))
  }, [format])

  function addTime() {
    let temp = [...times]
    temp.push(new Date())
    setTimes(temp)
  }

  function setLightCss() {
    document.documentElement.style.cssText = "--background-color: #111;"
  }

  function changeTime(e: number, time: Date) {
    let temp = [...times]
    temp[e] = time
    setTimes(temp)
  }

  function removeTime(e: number) {
    let temp = [...times]
    temp.splice(e, 1)
    setTimes(temp)
  }

  async function generateMessage() {
    let temp = "";
    times.forEach((e) => {
      temp += `${day ? `${e.toLocaleString("en-US", { "weekday": "long" })}: ` : ""}<t:${Math.round(e.getTime() / 1000)}:${format}> ${relative ? `(<t:${Math.round(e.getTime() / 1000)}:R>)` : ""} \r\n`;
    })
    await navigator.clipboard.writeText(temp)

    await alert("Message has been copied!")
  }

  return (
    <div className={`general-styling ${light ? "" : "dark"}`}>
      <FontAwesomeIcon icon={faSun} className={`sun ${light ? "" : "sun-dark"}`} onClick={() => { setLight((l) => !l) }} style={{ cursor: "pointer" }} />
      <div className='grid'>
        <h3 className='grid-title'>Time converter</h3>
        <Button onClick={addTime}>Add time</Button>
        <div className="flex">
          <div className='sub-grid'>
            {times.sort((a, b) => { return (a.getTime() - b.getTime()) }).map((e, i) => (
              <Fragment key={e.getTime()}>
                <div className='item'>
                  <div>{e.toDateString()}</div>
                  <input type={"datetime-local"} defaultValue={`${e.getFullYear()}-${(e.getMonth() + 1).toString().padStart(2, "0")}-${e.getDate().toString().padStart(2, "0")}T${e.getHours().toString().padStart(2, "0")}:${e.getMinutes().toString().padStart(2, "0")}`} onBlur={(e) => { changeTime(i, new Date(e.target.value)) }} />
                  <Button variant='danger' onClick={() => { removeTime(i) }}>Delete</Button>
                </div>
              </Fragment>
            ))}
          </div>
          <div className='neat'>
            <div className='sub-flex'>
              <label><input type="checkbox" checked={day} onChange={() => { setDay((d) => !d) }} />Include day?</label>
              <label className="discord-view"><input type="radio" checked={format === "t"} onChange={(e) => { setFormat(e.target.id) }} id="t" /> 12:15 </label>
              <label className="discord-view"><input type="radio" checked={format === "T"} onChange={(e) => { setFormat(e.target.id) }} id="T" /> 12:15:00 </label>
              <label className="discord-view"><input type="radio" checked={format === "f"} onChange={(e) => { setFormat(e.target.id) }} id="f" /> 27 December 2021 12:15 </label>
              <label className="discord-view"><input type="radio" checked={format === "F"} onChange={(e) => { setFormat(e.target.id) }} id="F" /> Monday 27 December 2021 12:15 </label>
              <label className="discord-view"><input type="radio" checked={format === "d"} onChange={(e) => { setFormat(e.target.id) }} id="d" /> 27/12/2021 </label>
              <label className="discord-view"><input type="radio" checked={format === "D"} onChange={(e) => { setFormat(e.target.id) }} id="D" /> 27 December 2021 </label>
              <label><input type="checkbox" checked={relative} onChange={() => { setRelative((r) => !r) }} />Include relative time?</label>
            </div>
            <div className='sub-flex'>
              <Button variant='success' className="button" onClick={generateMessage}>Copy to clipboard</Button>
            </div>
            <div className='output'>
              <textarea rows={8} style={{ borderRadius: "5px" }} className="only-small" />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default App;
